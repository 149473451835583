import React from 'react';

import { Tagline } from '../../CompanyPage.sc';

interface Props {
  tagline: string | undefined;
}

export const CompanyTagLine = ({ tagline }: Props) => {
  return tagline ? <Tagline>{tagline}</Tagline> : null;
};
